import React from 'react';
import ClassName from '@utils/helpers/ClassName';
import './container.scss';

interface ContainerProps {
  flex?: boolean;
  noPadding?: boolean;
  full?: boolean;
  className?: string;
}

const Container: React.FC<ContainerProps> = ({ flex, className, noPadding, full, children }) => (
  <div
    className={ClassName.join(
      'container',
      className,
      ClassName.bind({
        'container--flex': flex,
        'container--no-padding': noPadding,
        'container--full': full,
      })
    )}
  >
    {children}
  </div>
);

export default Container;
