import React, { useCallback, useEffect, useState } from 'react';
import HubspotForm from 'react-hubspot-form';
import IndexLayout from '@layouts/index';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Container from '@layouts/Container';
import TDBContainer from '@components/Container';
import Typography from '@components/Typography';
import './discovery.scss';
import Thumbnail from '@page-components/discovery/discovery-thumbnail.png';
import dashboardImage from '@page-components/discovery/dashboard_demo.png';

const formId = 'c221da98-ea95-44ac-87e3-e3e385f69aac';

const RequestDemo: React.FC = () => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const messageHandler = useCallback(
    (event: MessageEvent<any>) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        if (event.data.id === formId) {
          setFormSubmitted(true);
        }
      }
    },
    [setFormSubmitted]
  );

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  return (
    <>
      <IndexLayout
        pageName="request-demo"
        helmet={{
          title: 'Book a discovery call • TileDB',
          description:
            'Schedule a customized discovery call with one of our experts and see how TileDB resolves the complexity of multimodal data like population genomics, proteomics, single-cell and bioimaging.',
          shareImage: {
            url: Thumbnail,
            width: 2000,
            height: 1050,
          },
        }}
      >
        <Header />
        <main className="main">
          <div className="TDB-Request-demo">
            <TDBContainer>
              <Container flex className="TDB-Request-demo__container">
                <div className="TDB-Request-demo__main">
                  {!formSubmitted ? (
                    <>
                      <Typography as="h4" fontWeight="semi-bold" fontSize="subheading-2" className="mb-3">
                        Are you ready to see TileDB Cloud in action?
                      </Typography>
                      <Typography as="h2" className="mb-3" fontWeight="bold" color="neutral-800" fontSize="heading-2">
                        Request a discovery call
                      </Typography>
                      <Typography as="h4" fontWeight="semi-bold" fontSize="subheading-3" className="mb-8">
                        Built on a powerful shape-shifting array database, see how TileDB handles the complexities of non-traditional
                        “unstructured” multimodal data.
                      </Typography>
                    </>
                  ) : (
                    <Typography as="h2" className="mb-3" fontWeight="bold" color="neutral-800" fontSize="heading-2">
                      Thank you.
                    </Typography>
                  )}

                  <div className="TDB-Request-demo__form">
                    <HubspotForm portalId="7741339" formId={formId} loading={<div>Loading form...</div>} />
                  </div>
                </div>
                <img className="TDB-Request-demo__container__demo-image" src={dashboardImage} alt="dashboard" />
              </Container>
            </TDBContainer>
          </div>
        </main>
        <Footer variant="dark" />
      </IndexLayout>
    </>
  );
};

export default RequestDemo;
